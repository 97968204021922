import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import styles from './LeftBar.module.css'
import userZaglushka from '../../assets/images/leftBar/lk.svg'
import whiteStar from '../../assets/images/leftBar/whiteStar.svg'
import searchImg from '../../assets/images/leftBar/search.svg'
import calendar from '../../assets/images/leftBar/calendar.svg'
import closeModal from '../../assets/images/closeModal.svg'
import motivationArrow from '../../assets/images/leftBar/motivationArrow.svg'
import demotivation from '../../assets/images/leftBar/demotivationArrow.svg'
import SText from "../utils/SText";
import { numberWithSpaces, onImgError } from "../utils/other";
import Colors from "../utils/Colors";
import { Box, ClickAwayListener, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/reducers/user";
import { getTickets } from "../../redux/reducers/tickets";
import { getCategories } from "../../redux/reducers/categories";
import { NavLink, useLocation } from 'react-router-dom'
import cn from "classnames";
import debounce from "lodash.debounce";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { instance } from "../../api";
import { toast } from "react-toastify";
import Select from "react-select";
import { Filter, FilterAlt, FilterAltOff, Phone } from "@mui/icons-material";
import { useMatchMedia } from "../../hooks/hooks";

const LastUpdate = () => {
    const { lastUpdate } = useSelector(state => state.tickets)

    const [now, setNow] = useState(moment())

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(moment())
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    const diff = now.diff(moment(lastUpdate), 's')

    if (diff < 32) return null

    return <div className={ 'px-3' }>
        <SText color={ Colors.red } weight={ 500 }>
            { `Последние обновление было ${diff} сек назад. ` }
            <u className={ 'cursor-pointer' } onClick={ () => window.location.reload() }>{ 'Обновить' }</u>
        </SText>
    </div>
}

const LeftBar = () => {
    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(2)
    const { isDesktop } = useMatchMedia()

    useEffect(() => {
        dispatch(getUser())
        dispatch(getCategories())
    }, [])

    useEffect(() => {
        dispatch(getTickets({ id: activeTab, string: '' }))
    }, [activeTab])

    return <div className={ `flex flex-col ${isDesktop && 'h-screen'} bg-white` }>
        <User />
        <Tabs activeTab={ activeTab } setActiveTab={ id => setActiveTab(id) } />
        <Search />
        <LastUpdate />
        <Messenger />
    </div>
}

const User = () => {
    const [kpiModal, setKpiModal] = useState(false)
    const [atcModal, setAtcModal] = useState(false)
    const atc = sessionStorage.getItem('atc')
    const user = useSelector(state => state.user)
    const { isMobile, isDesktop } = useMatchMedia()

    return <div style={ isMobile ? { height: 40, width: '100%' } : {} } className={ styles.leftBarHeader }>
        <div style={ isMobile ? {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: 30,
            gap: 3
        } : {} } className={ styles.nameWrapper }>
            <div style={ { display: 'flex', flexDirection: 'column', gap: 0, alignItems: 'center' } }>
                { isDesktop && <div className={ styles.managerImage }>
                    { user?.photo ? <img src={ user?.photo } className={ 'w-14 h-14 rounded-full object-cover' } /> :
                        <div className={ styles.holderWrapper }><img src={ userZaglushka } /></div> }
                </div> }
                <div onClick={ () => setAtcModal(true) }
                    style={ { marginBottom: -8, cursor: 'pointer', width: 'fit-content' } }>
                    <SText size={ 14 } weight={ 500 } color={ '#fff' }>{ 'ATC' }</SText>
                    <SText size={ 14 } weight={ 500 } color={ '#fff' }>{ atc ? `: ${atc}` : '' }</SText>
                </div>
            </div>
            <div className={ styles.managerName }>
                <SText style={ { display: 'flex', alignItems: 'center', gap: 5 } } div size={ 18 } lineHeight={ 20 }
                    weight={ 700 } color={ '#fff' }>
                    <span>{ user.user.name + ' ' + user.user.surname }</span>
                    { isDesktop && <span className={ 'flex gap-1 items-center mt-1' }>
                        <img src={ whiteStar } />
                        <SText size={ 14 } weight={ 700 } lineHeight={ 14 } color={ '#fff' }>{ user.rating }</SText>
                    </span> }
                </SText>
            </div>
        </div>
        <div onClick={ () => setKpiModal(true) }
            style={ { cursor: 'pointer', display: isMobile ? 'flex' : 'block', gap: 5 } }>
            <div style={ isMobile ? { marginBottom: 0 } : {} } className={ styles.kpiWrapper }>
                <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                    lineHeight={ 12 }>{ `KPI ${numberWithSpaces(user.monthKPI.motivation - user.monthKPI.demotivation)}` }</SText>
            </div>
            <div className={ styles.kpiWrapper }>
                <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                    lineHeight={ 12 }>{ `KPI ${numberWithSpaces(user.dayKPI.motivation - user.dayKPI.demotivation)}` }</SText>
            </div>
        </div>
        { kpiModal && <KpiModal dayKPI={ user.dayKPI } monthKPI={ user.monthKPI } onClose={ () => setKpiModal(false) } /> }
        { atcModal && <AtcModal onClose={ () => setAtcModal(false) } /> }
    </div>
}

const AtcModal = ({ onClose }) => {
    const [value, setValue] = useState('')

    const onConfirm = () => {
        sessionStorage.setItem('atc', value)
        instance.post('/user/set-inner-phone', {
            "shopId": 2,
            "phone": value
        }).then(r => {
            toast.success("Номер установлен")
        })
        setTimeout(() => {
            onClose()
        }, 200)
    }

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }
    >
        <Box style={ { position: 'relative' } }>
            <div className={ styles.modal }>
                <div style={ { marginBottom: 5 } }><SText weight={ 500 }>{ 'Введите номер' }</SText></div>
                <input autoFocus onKeyPress={ (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        onConfirm()
                    }
                } } value={ value } onChange={ (e) => setValue(e.target.value) } className={ styles.atcInput }
                    type="number" />
                <div onClick={ onConfirm } className={ styles.confirmNumberBtn }><SText weight={ 500 }
                    color={ '#fff' }>{ 'Подтвердить' }</SText>
                </div>
            </div>
            <div onClick={ onClose } className={ styles.closeModal }><img src={ closeModal } /></div>
        </Box>
    </Modal>
}

const KpiModal = ({ onClose, monthKPI, dayKPI }) => {

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }
    >
        <Box style={ { position: 'relative' } }>
            <div className={ styles.modal }>
                <div className={ styles.kpi }>
                    <SText div style={ { marginBottom: 10 } } size={ 12 } weight={ 700 } color={ Colors.darkGray }
                        lineHeight={ 12 }>{ `KPI ${numberWithSpaces(monthKPI.motivation - monthKPI.demotivation)}` }</SText>
                    <div className={ styles.motivation }>
                        <img src={ motivationArrow } />
                        <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                            lineHeight={ 12 }>{ `Мотивация ${numberWithSpaces(monthKPI.motivation)}` }</SText>
                    </div>
                    <div className={ styles.demotivation }>
                        <img src={ demotivation } />
                        <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                            lineHeight={ 12 }>{ `Демотивация -${numberWithSpaces(monthKPI.demotivation)}` }</SText>
                    </div>
                </div>
                <div className={ styles.kpi }>
                    <SText div style={ { marginBottom: 10 } } size={ 12 } weight={ 700 } color={ Colors.darkGray }
                        lineHeight={ 12 }>{ `KPI ${numberWithSpaces(dayKPI.motivation - dayKPI.demotivation)}` }</SText>
                    <div className={ styles.motivation }>
                        <img src={ motivationArrow } />
                        <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                            lineHeight={ 12 }>{ `Мотивация ${numberWithSpaces(dayKPI.motivation)}` }</SText>
                    </div>
                    <div className={ styles.demotivation }>
                        <img src={ demotivation } />
                        <SText size={ 12 } weight={ 500 } color={ Colors.darkGray }
                            lineHeight={ 12 }>{ `Демотивация -${numberWithSpaces(dayKPI.demotivation)}` }</SText>
                    </div>
                </div>
            </div>
            <div onClick={ onClose } className={ styles.closeModal }><img src={ closeModal } /></div>
        </Box>
    </Modal>
}

const Tabs = ({ activeTab, setActiveTab }) => {
    const categories = useSelector(state => state.categories.categories)

    return null

    return <div className={ styles.tabsWrapper }>
        {
            categories.map(item => <div key={ item.id } onClick={ () => setActiveTab(item.id) }
                style={ { background: activeTab == item.id ? '#007AFF' : '#F0F2F5' } }
                className={ styles.tab }><SText size={ 12 }
                    lineHeight={ 12 }
                    weight={ activeTab == item.id ? 700 : 500 }
                    color={ activeTab == item.id ? '#fff' : Colors.darkGray }>{ item.name }</SText>
            </div>)
        }
    </div>
}


const types = {
    '-1': 'Любой тип',
    '1': 'Telegram',
    '2': 'WhatsApp',
    '3': 'SMS',
    '4': 'Email',
    '5': 'Приложение',
    '6': 'Push',
    '7': 'Жалоба',
    '8': 'Запрос звонка',
    '9': 'Обращение',
    '10': 'Звонок из АТС',
    '11': 'Звонок из К.Ц.',
    '12': 'Заказ',
    '13': 'Чат с курьером',
    '14': 'Email',
    '20': 'Пропущенный',
    '99': 'Согласование',
}

const statuses = {
    /*'-1': 'Все статусы',*/
    '0': 'Новые',
    '1': 'В работе',
    '3': 'Закрыты',
}

const shops = {
    '1': 'SM',
    '2': 'ECO',
}

function toValue(v, options) {
    return options.find(op => op.value === v)
}

const InitialFilters = JSON.parse(localStorage.getItem('filters')) ??
    { status: '0', type: '-1', shop: '2' }

function mapToOptions(dict, count) {
    const getCount = (value) => {
        const n = count?.[value]?.total ?? count?.[value] ?? 0
        return n ? ` [${n}]` : ''
    }
    return Object
        .entries(dict)
        .map(([value, label]) => ({
            value,
            label: label + getCount(value)
        }))
        .sort((a, b) => +a.value - b.value)
}

const Search = () => {
    const [value, setValue] = useState('')
    const dispatch = useDispatch()
    const [calendarValue, setCalendarValue] = useState(false)
    const [calendarVisible, setCalendarVisible] = useState(false)
    const debouncedTick = useCallback(debounce(tick, 1000), [])
    const [ticker, setTicker] = useState(0) // костыль чето
    const location = useLocation()
    const counts = useSelector(state => state.tickets.counts)

    const [filters, setFilters] = useState(InitialFilters)
    const shopCounts = counts.shops?.[filters.shop] || {}

    const typeOptions = mapToOptions(types, shopCounts.types)
    const statusOptions = mapToOptions(statuses, shopCounts.statuses)
    const shopOptions = mapToOptions(shops, counts.shops)

    const { isMobile } = useMatchMedia()

    function loadTickets() {
        const date = calendarValue ? moment(calendarValue).format('YYYY-MM-DD') : ''
        dispatch(getTickets({
            id: filters.shop,
            string: value,
            date,
            type: filters.type,
            status: filters.status,
        }))
    }

    useEffect(() => {
        loadTickets(calendarValue, value)
    }, [ticker])

    function tick() {
        setTicker(t => t + 1)
    }

    useEffect(() => {
        debouncedTick()
    }, [value])

    useEffect(() => {
        tick()
        localStorage.setItem('filters', JSON.stringify(filters))
    }, [calendarValue, location, filters])


    const onQueryChange = (e) => {
        setValue(e?.target?.value)
    }

    const createFilterHandler = (fieldName) => (option) => {
        setFilters(prev => ({ ...prev, [fieldName]: option.value }))
    }

    useEffect(() => {
        let timer
        (function ping() {
            timer = setTimeout(() => {
                tick()
                ping()
            }, 20000)
        })()
        return () => clearTimeout(timer)
    }, [])


    return <div style={ isMobile ? { width: '100%' } : {} }>
        <div className={ 'h-22 w-full p-2 flex gap-3' }>
            <div className={ 'flex-1 flex gap-2' }>
                <Statuses onClick={ createFilterHandler('shop') } type={ 'shop' } filters={ filters }
                    statusOptions={ shopOptions } />
                <div className={ 'w-0.5 bg-gray-300' } />
                <Statuses onClick={ createFilterHandler('status') } type={ 'status' } filters={ filters }
                    statusOptions={ statusOptions } />
            </div>
        </div>

        <div style={ isMobile ? { padding: 5, width: '100%' } : {} } className={ styles.searchBlock }>
            <div className={ styles.search }>
                <img src={ searchImg } />
                <input value={ value } onChange={ onQueryChange } type="search" />
            </div>
            <Select className={ 'flex-1' }
                value={ toValue(filters.type, typeOptions) }
                options={ typeOptions }
                onChange={ createFilterHandler('type') }

            />
            { calendarValue && <div onClick={ () => setCalendarValue(false) }
                className={ 'bg-gray-700 px-3 absolute right-2 top-16 py-2 items-baseline flex gap-2 rounded-md cursor-pointer' }>
                <SText color={ '#fff' } size={ 12 } weight={ 700 }>
                    { 'c ' + moment(calendarValue).format('L') }
                </SText>
                <SText color={ Colors.red } weight={ 900 }> x</SText>
            </div> }
            <div onClick={ () => setCalendarVisible(prev => !prev) } className={ styles.calendar }>
                <img src={ calendar } />
                { calendarVisible && <ClickAwayListener onClickAway={ () => setCalendarVisible(true) }>
                    <div className={ styles.datePicker }>
                        <Calendar onClickDay={ setCalendarValue } />
                    </div>
                </ClickAwayListener> }
            </div>
        </div>
    </div>
}

const Statuses = ({ filters, statusOptions, onClick, type = '' }) => {

    return <>
        { statusOptions.map(item => <div onClick={ () => onClick(item) }
            key={ type + item.value }
            style={ { background: item.value == filters[type] ? '#007AFF' : '#F0F2F5' } }
            className={ 'bg-gray-300 rounded-full px-2 py-2 cursor-pointer flex-1 flex justify-center min-w-fit' }>
            <SText size={ 12 }
                weight={ item.value == filters[type] ? 700 : 500 }
                color={ item.value == filters[type] ? '#fff' : Colors.darkGray }>{ item.label }</SText>
        </div>) }
    </>
}

const Messenger = () => {
    const { tickets } = useSelector(state => state.tickets)
    const { isMobile } = useMatchMedia()

    const { premiumTickets, normalTickets } = useMemo(() => {
        const premiumTickets = []
        const normalTickets = []

        tickets && tickets.map(ticket => {
            const profitUser = ticket.user_data.profit_status && ticket.user_data.profit_status !== null ? true : false
            if (profitUser) {
                premiumTickets.push(ticket)
            } else {
                normalTickets.push(ticket)
            }
        })

        return { premiumTickets, normalTickets }
    }, [tickets])

    return <div className={ isMobile ? styles.messengerWrapperMobile : styles.messengerWrapper }>
        {
            premiumTickets && premiumTickets.map(item => <Chat key={ item.id } data={ item } />)
        }
        {
            normalTickets && normalTickets.map(item => <Chat key={ item.id } data={ item } />)
        }
    </div>
}


const Chat = ({ data }) => {

    const [delayString, setDelayString] = useState('')
    const { isMobile } = useMatchMedia()

    function calculateDelay() {
        const end = moment()
        const start = moment(data.date_create, 'YYYY-MM-DD HH:mm:SS')
        const interval = moment.duration(end.diff(start, 's'), 's')

        let format = "mm:ss"
        if (interval.asHours() > 1)
            format = 'h ч. mm:ss'
        if (interval.asDays() > 1)
            format = 'D д. h ч. mm:ss'

        let f = moment.utc(interval.asMilliseconds()).format(format);

        const str = data.status === 0 && interval.asMinutes() > 1 ? f : ''
        setDelayString(str)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            calculateDelay()
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const profitUser = data.user_data.profit_status && data.user_data.profit_status !== null ? true : false

    return <NavLink to={ '/tickets/' + data.id }
        className={ ({ isActive }) => cn(isMobile ? styles.chatWrapperMobile : styles.chatWrapper, isActive && styles.active) }
    >
        <div className={ styles.chatTabsAndTimer }>
            <div style={ { display: 'flex', alignItems: 'center', gap: 11 } }>
                <div className={ data.type == 99 ? styles.messageFromAccent : styles.messageFrom }>
                    <SText size={ 11 } lineHeight={ 10 } weight={ 700 } color={ '#fff' }>
                        { types[data.type] }
                    </SText>
                </div>
                { !!data.order_id && <div className={ styles.orderNumber }>
                    <SText size={ 14 } weight={ 900 } lineHeight={ 18 }
                        color={ '#6c6e7a' }>
                        { `${data.order_id}` }
                    </SText>
                </div> }
            </div>
            <SText size={ 14 } weight={ 900 } lineHeight={ 14 } color={ Colors.red }>{ delayString }</SText>
        </div>
        <div style={ isMobile ? { paddingBottom: 5, height: 48 } : {} } className={ styles.userChat }>
            <div style={ { display: 'flex', alignItems: 'center', gap: 20, height: isMobile ? 42 : 'initial' } }>
                <div style={ isMobile ? { height: 20, width: 20 } : {} } className={ styles.managerImage }>
                    { data?.user_data?.photo ?
                        <img style={ {
                            width: isMobile ? 20 : 50,
                            height: isMobile ? 20 : 50,
                            borderRadius: '50%',
                            objectFit: 'cover'
                        } }
                            src={ data?.user_data?.photo } /> : <img src={ userZaglushka } /> }
                    { data.dispatcherInfo &&
                        <img
                            style={ isMobile ? { height: 20, width: 20 } : {} }
                            className={ 'w-10 h-10 flex-shrink-0 border-orange-400 border-2 rounded-full object-cover absolute -bottom-2 -right-2' }
                            src={ data.dispatcherInfo?.photo }
                            onError={ onImgError }
                        /> }
                </div>

                <div className={ styles.chatName }>
                    <div style={ { display: 'flex', marginBottom: isMobile ? 0 : 4 } } className={ isMobile && styles.nameMobile }>
                        { profitUser ? <span className={ styles.profitUser }>!</span> : null }
                        <SText size={ isMobile ? 12 : 16 }
                            weight={ 700 }
                            color={ Colors.darkGray }
                            lineHeight={ isMobile ? 12 : 16 }>{ data.user_data.fullName || data.user_data.phone }</SText>
                    </div>
                    <div>
                        <SText size={ isMobile ? 10 : 14 } weight={ 500 } color={ '#949ea6' }
                            lineHeight={ isMobile ? 10 : 14 }>{ getRegion(data.user_data.region) }
                        </SText>
                    </div>
                </div>
            </div>
            { data.unread !== 0 && <div className={ styles.incomingMessages }>
                <SText size={ 14 } color={ '#fff' } weight={ 700 } lineHeight={ 14 }>{ `${data.unread}` }</SText>
            </div> }
        </div>
    </NavLink>
}


function getRegion(region) {
    switch (region) {
        case '77':
            return 'Москва'
        case '78':
            return 'Санкт-Петербург'
        default:
            return ''
    }
}

export default LeftBar
